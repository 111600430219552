import React, { useState } from "react";
import { Navbar, Container, Nav, Form, FormControl, Button, Row, Col } from 'react-bootstrap'
import { getPlacesData } from "../../api";

const Header = ({ setIsLoading, setPlaces, circleControl, places, searchString, setSearchString, setFilteredPlaces, setCheckedState }) => {




    const [standAloneSearchBox, setStandAloneSearchBox] = useState(null);

    const onLoad = (standAlone) => {
        setStandAloneSearchBox(standAlone);
    }

    const onPlacesChanged = () => {
        setIsLoading(true);
        console.log(standAloneSearchBox.getPlaces())
        console.log(standAloneSearchBox.getBounds())
        setPlaces(standAloneSearchBox.getPlaces())
        setIsLoading(false)



    }

    const rad = function (x) {
        return x * Math.PI / 180;
    };

    const getDistance = function (p1, p2) {

        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat());
        var dLong = rad(p2.lng - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat)) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
    };


    const handleSearchTextChange = (e) => {
        setSearchString(e.target.value)

    }
    const FormButtonClick = (e) => {
        setIsLoading(true)
        setPlaces([]);
        setFilteredPlaces(null);
        setCheckedState([]);
        e.preventDefault();
        if (searchString.length <= 3) {
            alert("Please Enter valid Search String")
            setIsLoading(false)
            return;
        }

        getPlacesData(searchString).then((data) => {
            // console.log(data.results)
            setPlaces(data.results)
            setCheckedState(Array(data.results.length).fill(false))
            setIsLoading(false)
        })

    }
    const restFilter = () => {

        setFilteredPlaces(null);
        setCheckedState(Array(places.length).fill(false))

    }
    const addToDatbase = () => {
        let bounds = circleControl.getBounds()
        let filteredPlaces = places?.filter(function (place) {
            if (bounds.contains({ lat: place.geometry.location.lat, lng: place.geometry.location.lng })) {
                const distanceInMeters = getDistance(circleControl.getCenter(), { lat: place.geometry.location.lat, lng: place.geometry.location.lng })
                if (distanceInMeters <= circleControl.radius) {
                    return true;
                }

            }
            return false;
        });
        setFilteredPlaces(filteredPlaces);
        setCheckedState(Array(filteredPlaces.length).fill(false))

        /*places?.map((place, i) => {
            if (bounds.contains({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })) {
                const distanceInMeters = getDistance(circleControl.getCenter(), { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })//window.google.maps.geometry.spherical.computeDistanceBetween(circleControl.getCenter(), { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                if (distanceInMeters <= circleControl.radius) {
                  
                }


            }
        });*/
    }

    return (

        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Navbar.Brand href="#home">Google Place</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                    </Nav>
                    <div className="d-flex flex-row flex-fill">
                        <div className="flex-fill">



                        </div>
                        <div className="d-flex flex-fill align-items-center flex-row">

                            <Form.Label className="me-2">Search</Form.Label>

                            {/*  <StandaloneSearchBox className="flex-grow-1 me-2"
                                onLoad={onLoad}
                                onPlacesChanged={onPlacesChanged}
    >*/}
                            <FormControl type="text" placeholder="Search" className="me-2" aria-label="Search" value={searchString} onChange={handleSearchTextChange} />
                            {/*   </StandaloneSearchBox>*/}


                            <Button variant="outline-success" className="me-2" onClick={FormButtonClick}>Search</Button>
                            <Button variant="outline-success" className="me-2" onClick={addToDatbase}>Filter</Button>
                            <Button variant="outline-success" className="me-2" onClick={restFilter}>Reset</Button>

                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    )
}

export default Header;