import React from "react";
import { Card } from "react-bootstrap";
import styles from '../../mystyle.module.css'
const PlaceDetails = ({ place, index, startIndex, endIndex, setStartIndex, setEndIndex, checkedState, setCheckedState }) => {

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState?.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
    }
    const dosetStartIndex = (index) => {
        if (endIndex === index) {
            setEndIndex(-1)

        }
        setStartIndex(index)

        const updatedCheckedState = checkedState?.map((item, i) =>
            i === index ? !item : item
        );

        setCheckedState(updatedCheckedState);

    }

    const dosetEndIndex = (index) => {
        if (startIndex === index) {
            setStartIndex(-1)

        }
        setEndIndex(index)


        const updatedCheckedState = checkedState?.map((item, i) =>
            i === index ? !item : item
        );

        setCheckedState(updatedCheckedState);
    }
    return (
        <Card className="mb-2">

            <Card.Header className={`${startIndex === index ? "bg-primary text-white" : ""} ${endIndex === index ? "bg-danger text-white" : ""} ${styles.revealer}`}>
                <div className="d-flex align-items-center">
                    <input type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name="checked"
                        checked={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                        className="me-2"
                    />
                    {place.name} {startIndex === index ? "[Starting Address]" : ""}
                    {endIndex === index ? "[Ending Address]" : ""}

                </div>
            </Card.Header>
            <Card.Body>
                <Card.Text>{place.formatted_address}</Card.Text>
                {/* <div className="d-flex justify-content-between">
                    <p>Latitude</p>
                    <p>{place.geometry.location.lat}</p>

                </div>
                <div className="d-flex justify-content-between">
                    <p>Longitude</p>
                    <p>{place.geometry.location.lng}</p>

    </div>*/ }
                <div className="d-flex justify-content-between">
                    <p>Rating</p>
                    <p>{place.rating}</p>

                </div>

                <div className="d-flex justify-content-between">
                    {index === startIndex ? (
                        ""
                    ) : (<button className="btn btn-secondary me-2 " onClick={() => dosetStartIndex(index)}>Set as start address</button>)

                    }

                    {index === endIndex ? ("") : (
                        <button className="btn btn-danger me-2 " onClick={() => dosetEndIndex(index)}>Set as End Address</button>)}

                </div>

            </Card.Body>
        </Card>
    );
}
export default PlaceDetails;