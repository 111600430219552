import axios from "axios";

export const getPlacesData = async (searchString) => {
    const key = "AIzaSyBR1mFt7YcZ3sH8YgV4PWlYK884Vl5_Lx0";
    const URL = "./api.php?url=" + encodeURIComponent("https://maps.googleapis.com/maps/api/place/textsearch/json?query=" + encodeURIComponent(searchString) + "&key=" + key);
    const options = {


        headers: {
            'Accept': 'application/json',


        }
    };

    try {
        //const { data: { data } } = await axios.get(URL, options)
        const data = await axios.get(URL, options)

        return data.data;
    } catch (error) {
        console.log(error)
    }
}

export const getOptimumRoute = async (places) => {
    const key = "AIzaSyBR1mFt7YcZ3sH8YgV4PWlYK884Vl5_Lx0";
    const arr_length = places.length - 1;
    if (arr_length <= 2) {
        return;
    }
    const origin = places[0].geometry.location.lat + "," + places[0].geometry.location.lng;
    const destination = places[arr_length].geometry.location.lat + "," + places[arr_length].geometry.location.lng;
    let waypoints = "";
    for (let idx = 1; idx < arr_length; idx++) {
        waypoints += "|" + places[idx].geometry.location.lat + "," + places[idx].geometry.location.lng;
    }
    const URL = "./api.php?url=" + encodeURIComponent("https://maps.googleapis.com/maps/api/directions/json?origin=" + origin + "&destination=" + destination + "&waypoints=optimize:true" + waypoints + "&mode=driving" + "&key=" + key);
    const options = {


        headers: {
            'Accept': 'application/json',


        }
    };

    try {
        //const { data: { data } } = await axios.get(URL, options)
        const data = await axios.get(URL, options)

        return data.data;
    } catch (error) {
        console.log(error)
    }
}
