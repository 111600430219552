import React from "react";
import { useState } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { getOptimumRoute } from "../../api";
import PlaceDetails from "../PlaceDetails/PlaceDetails";
import { CSVLink } from "react-csv"
const List = ({ places, isLoading, setFilteredPlaces, checkedState, setCheckedState, setPath, setShowPolyLine }) => {
    const [startIndex, setStartIndex] = useState(-1)
    const [endIndex, setEndIndex] = useState(-1)
    const [csvButtonVisible, setCsvButtonVisible] = useState(false)
    const [csvData, setCSVData] = useState([])

    const exportEvent = () => {
        let request_places = [];
        let index = 0;
        if (startIndex == -1 || endIndex == -1) {
            alert("Please choose Starting and Ending Address");
            return;
        }
        request_places[index++] = places[startIndex]
        let arr_length = places.length;
        for (let idx = 0; idx < arr_length; idx++) {
            if (idx === startIndex || idx === endIndex) {
                continue;
            }
            if (checkedState[idx] === true) {
                request_places[index++] = places[idx]
            }


        }
        request_places[index] = places[endIndex]
        console.log(request_places)

        getOptimumRoute(request_places).then((data) => {
            console.log(data);

            const route = data.routes[0];
            const { legs, overview_polyline, waypoint_order } = route
            setShowPolyLine(true)
            const polyline_path = window.google.maps.geometry.encoding.decodePath(overview_polyline.points)
            setPath(polyline_path)

            arr_length = request_places.length
            index = 0;
            let pindex = 0;
            let csv = [];
            csv[index++] = ['Name', 'Address', 'Latitude', 'Longitude']
            csv[index++] = [request_places[0].name, request_places[0].formatted_address, request_places[0].geometry.location.lat, request_places[0].geometry.location.lng];
            let w_length = waypoint_order.length;
            for (let idx = 0; idx < w_length; idx++) {
                pindex = waypoint_order[idx] + 1
                csv[index++] = [request_places[pindex].name, request_places[pindex].formatted_address, request_places[pindex].geometry.location.lat, request_places[pindex].geometry.location.lng];
            }


            csv[index++] = [request_places[arr_length - 1].name, request_places[arr_length - 1].formatted_address, request_places[arr_length - 1].geometry.location.lat, request_places[arr_length - 1].geometry.location.lng];

            setCsvButtonVisible(true)
            setCSVData(csv)
        })
    }

    const setStartAddress = (event, place) => {
        console.log(place.name)
        const index = places.findIndex(e => e.place_id === place.place_id)
        places[index].startAddress = 1;
        setFilteredPlaces(places)
    }

    return (
        <Container>
            {isLoading ? (
                <div className="text-center mt-2 pt-2">
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <div style={{ 'height': '90vh', 'overflowY': 'auto', 'overflowX': 'hidden' }}>
                    <div className="d-flex justify-content-between">
                        <Button variant="outline-success" className="me-2 mt-2" onClick={exportEvent}>Optimize</Button>
                        {csvButtonVisible ? (
                            <Button variant="outline-success" className="me-2 mt-2" onClick={exportEvent}>
                                <CSVLink data={csvData}
                                    filename="results.csv"
                                >Export</CSVLink>
                            </Button>
                        ) : ("")}
                    </div>
                    {places?.map((place, i) => (
                        <Row key={i}>
                            <Col xs={12}>
                                <PlaceDetails
                                    place={place}
                                    index={i}
                                    startIndex={startIndex}
                                    endIndex={endIndex}
                                    setStartIndex={setStartIndex}
                                    setEndIndex={setEndIndex}
                                    checkedState={checkedState}
                                    setCheckedState={setCheckedState}
                                />
                            </Col>
                        </Row>
                    ))}
                </div>
            )}
        </Container>
    )
}

export default List;