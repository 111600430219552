import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import List from "./components/List/List";
import Map from "./components/Map/Map";
import { Container, Row, Col } from "react-bootstrap";

const App = () => {
    const [places, setPlaces] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 })
    const [circleControl, setCircleControl] = useState(null)
    const [gmap, setGmap] = useState(null)
    const [searchString, setSearchString] = useState("")
    const [filteredPlaces, setFilteredPlaces] = useState([]);
    const [path, setPath] = useState([]);
    const [showPolyLine, setShowPolyLine] = useState(false)
    const [checkedState, setCheckedState] = useState([]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
            setCoordinates({ lat: latitude, lng: longitude })
        })
    }, [])

    useEffect(() => {
        if (places.length > 0) {
            setCoordinates({ lat: places[0].geometry.location.lat, lng: places[0].geometry.location.lng })
        }
    }, [places])

    return (
        <>
            <Header
                setIsLoading={setIsLoading}
                setPlaces={setPlaces}

                circleControl={circleControl}
                places={places}

                searchString={searchString}
                setSearchString={setSearchString}
                setFilteredPlaces={setFilteredPlaces}
                setCheckedState={setCheckedState}

            />
            <Container fluid>
                <Row>
                    <Col sm={12} md={4}>
                        <List
                            places={filteredPlaces ?? places}
                            isLoading={isLoading}
                            setFilteredPlaces={setFilteredPlaces}
                            checkedState={checkedState}
                            setCheckedState={setCheckedState}
                            setPath={setPath}
                            setShowPolyLine={setShowPolyLine}

                        />
                    </Col>
                    <Col sm={12} md={8}>
                        <Map
                            setCoordinates={setCoordinates}
                            places={places}
                            coordinates={coordinates}
                            setCircleControl={setCircleControl}
                            setGmap={setGmap}
                            showPolyLine={showPolyLine}
                            path={path}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}


export default App;