import React from "react";
import GoogleMapReact from "google-map-react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import mapStyles from "./mapStyles";
import MapMarker from "../MapMarker/MapMarker";
import { GoogleMap, LoadScript, Circle, Polyline } from "@react-google-maps/api";

const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 600,
    zIndex: 1,
    editable: true
}

const apiIsLoaded = (map, maps, center) => {
    /*  const circle = new google.maps.Circle({
          strokeColor: '#ff0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#ff0000',
          fillOpacity: 0.3,
          map,
          center: center,
          radius: 600,
          editable: true
      })*/
}


const Map = ({ setCoordinates, coordinates, places, setCircleControl, setGmap, showPolyLine, path }) => {

    const containerStyle = {
        width: '100%',
        height: '100vh'
    };

    const onLoad = (circle) => {
        setCircleControl(circle)
    }

    const onGoogleLoad = (map) => {
        setGmap(map)
    }

    return (

        <div style={{ height: '100vh', width: '100%' }}>
            {/* <LoadScript
                googleMapsApiKey="AIzaSyBR1mFt7YcZ3sH8YgV4PWlYK884Vl5_Lx0"
    >*/}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={coordinates}
                zoom={14}
                onLoad={onGoogleLoad}
            >
                {/* <GoogleMapReact

                bootstrapURLKeys={{ key: 'AIzaSyBR1mFt7YcZ3sH8YgV4PWlYK884Vl5_Lx0' }}
                defaultCenter={coordinates}
                center={coordinates}
                defaultZoom={14}
                margin={[50, 50, 50, 50]}
                options={{ disableDefaultUI: true, zoomControl: true, styles: mapStyles }}
                onChange={(e) => {
                    console.log(e)
                    //setCoordinates({ lat: e.center.lat, lng: e.center.lng })
                    //  setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw })
                }}

                onChildClick={(child) => {
                    //  setChildClicked(child)
    
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, coordinates)}
            >*/}
                {places?.map((place, i) => (

                    <MapMarker
                        position={
                            {
                                lat: Number(place.geometry.location.lat),
                                lng: Number(place.geometry.location.lng)
                            }
                        }
                        txt={place.name + "\n" + place.formatted_address}

                        key={i}

                    />


                ))}
                <Circle
                    // optional
                    onLoad={onLoad}
                    // optional
                    // onUnmount={onUnmount}
                    // required
                    center={coordinates}
                    // required
                    options={options}
                />

                {showPolyLine ? (
                    <Polyline
                        path="{path}"
                    />
                ) : (
                    ""
                )
                }

            </GoogleMap>
            {/*</LoadScript>*/}
        </div>
    )
}

export default Map;