import React from "react";
import { PlaceStyle } from "./styles";
import "./MapMarker.css"
import { InfoBox, Marker } from "@react-google-maps/api";
const MapMarker = ({ position, txt }) => {
    const options = { closeBoxURL: '', enableEventPropagation: true };
    return (

        <Marker style={PlaceStyle}
            position={position}
            title={txt}
            animation={2}
        />






    )
    {/* <InfoBox

            options={options}
            position={position}
        >
            <div style={{ width: '150px', height: '150px' }}>
                <div
                    className="pin bounce"
                    style={{ backgroundColor: '#f00', cursor: 'pointer' }}
                    title={txt}
                />
                <div className="pulse" />
            </div>
</InfoBox>*/}
}

export default MapMarker;